import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Image from "../../../components/image"
import ImageFrame from "../../../components/image-frame"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"

const HoelderlinLiebtWandern = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        titelbild: file(
          relativePath: { eq: "hoelderlin-liebt/einleitung-wandern.jpg" }
        ) {
          ...mediumImage
        }
        gang: file(relativePath: { eq: "leichte-sprache/gang.jpg" }) {
          ...mediumImage
        }
        zwingel: file(
          relativePath: {
            eq: "hoelderlins-orte/zwingel-weg-am-hoelderlinturm.jpg"
          }
        ) {
          ...mediumImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt das Wandern"
        description="Hölderlin wandert gerne. Manchmal sogar mehr als 50 Kilometer an einem Tag. Mehrere Tage und Wochen hintereinander. Später entfernt er sich kaum noch von seinem Zuhause im Turm. Aber er wandert noch immer gern."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "… das Wandern",
                link: "/sonderausstellungen/hoelderlin-liebt/wandern",
              },
            ]}
          />
          <PageTitle>Hölderlin liebt das Wandern</PageTitle>
          <Grid columns={[1, 2]} alignY="center">
            <Stack>
              <Paragraph dropcap={true}>
                Hölderlin wandert gerne. <br />
                Manchmal sogar mehr als 50 Kilometer an einem Tag. <br />
                Mehrere Tage und Wochen hintereinander. <br />
                Später entfernt er sich kaum noch von seinem Zuhause im Turm.
                <br />
                Aber er wandert noch immer gern. <br />
                Stundenlang geht er im Hausflur auf und ab. <br />
                Oder draußen auf dem schmalen Weg vor dem Turm.
              </Paragraph>
            </Stack>
            <ImageFrame>
              <Image
                image={data.titelbild.childImageSharp.gatsbyImageData}
                alt="Eine Illustration zeigt einen Mann mit Wanderstab."
              />
            </ImageFrame>
          </Grid>
        </Stack>

        <Stack>
          <Grid columns={[1, 2]}>
            <Image
              image={data.gang.childImageSharp.gatsbyImageData}
              alt="Ein langer Gang mit weißen Wänden und einem Natursein-Boden. An der Decke hängen Lichtschienen. Ein großes schwarzes Pendel schwingt an der Wand hin und her."
              attribution="David Franck"
            />
            <Stack space={6}>
              <Heading as="h2" level={3}>
                Der Hausflur
              </Heading>
              <Paragraph>
                In diesem Gang ist Hölderlin oft auf und ab gegangen.
                <br />
                Ernst Zimmer erzählt:
                <br />
                Mit dem Umbau in meinem Haus
                <br />
                hat Hölderlin »einen Gang von 42 Schuh Länge bekommen,
                <br />
                den er auch alle Tage mit gewaltigen Schritten« entlang geht.
              </Paragraph>
            </Stack>
          </Grid>
        </Stack>

        <Stack>
          <Grid columns={[1, 2]}>
            <Stack space={6}>
              <Heading as="h2" level={3}>
                Der Zwingel
              </Heading>
              <Paragraph>
                Am Morgen will Hölderlin erst einmal spazieren gehen.
                <br />
                Aber Hölderlin ist krank.
                <br />
                Er kann nicht alleine in die Felder gehen, in den Wald oder am
                Fluss entlang.
                <br />
                Also geht er in den Zwingel.
                <br />
                Der Zwingel liegt zwischen der alten Stadt-Mauer und dem Neckar.
                <br />
                Es ist ein kurzer Weg.
                <br />
                Aber der Himmel leuchtet und die Wolken ﬂiegen auch hier über
                Hölderlin hinweg.
                <br />
                Hölderlin geht auf und ab.
                <br />
                Er hört seine Schritte auf dem Boden.
                <br />
                Er sagt: Ich höre mich gehen.
                <br />
                Also weiß ich, dass ich noch auf der Welt bin.
              </Paragraph>
            </Stack>
            <Image
              image={data.zwingel.childImageSharp.gatsbyImageData}
              alt="Ein schwarz-weiß Foto zeigt einen schmalen Weg vor dem Höderlinturm."
              attribution="Barbara Klemm"
            />
          </Grid>
        </Stack>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtWandern
